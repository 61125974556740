import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { AccordionList, Feature, PageLayout, PriceCalculator, PriceList, TalkWithExpert } from '../../components'

const Platform = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {in: [
        "00-feature-pricing.png"
      ]}}) {
        images: edges {
          node {
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const fluidImage = (path) => {
    const images = data.allFile.images
    const imageNode = images.find(item => item.node.relativePath === path)
    return imageNode && imageNode.node.childImageSharp.fluid
  }

  return (
    <PageLayout 
      location={location}
      title="Pricing on Hippobyte"
      titleTemplate="Hippobyte"
      description="Simple, predictable pricing for data collection needs, Hippobyte can help you save."
      footer={true}
      pageTheme="dark"
    >
    <Feature 
      themeColor="#F6F7FC"
      reverse
      image={{
        type: "fluid",
        content: fluidImage("00-feature-pricing.png"),
        width: "100%",
        style: { bottom: "30%", left: "0" }
      }}
      aside={{
        header: "Pricing",
        title: "Simple, flexible pricing, designed to scale.",
        description: "Flexible pricing structure that fits your current and future use cases, we assess your requirements and outline a plan that meets your objectives. Hippobyte's consumption-based pricing model scales with your needs, pay only for the data you consume.",
      }}
    />
    <PriceList />
    <PriceCalculator />
    <AccordionList
      fontSize="large"
      color="black"
      header={{
        title: "Billing and Pricing FAQ",
      }}
      items={[
        {
          title: "Is there a free trial period?",
          description: `<p>Yes. We offer a risk-free trial where you can test connectors and platform features at no cost for up to 14-days.</p>`
        },
        {
          title: "What is a MDE?",
          description: `<p>An MDE or Monthly Data Event is a unit that corresponds to a data records synchronized by the Hippobyte platform.</p>`
        },
        {
          title: "What are your billing options?",
          description: `<p>Hippobyte offers monthly and annual subscriptions paid via invoice.</p>`
        },
        {
          title: "Do you offer government, education institutions, or non-profit pricing?",
          description: `<p>Yes! Everyone deserves the best tools, contact us to learn more about our special rates for non-profits.</p>`
        },
        {
          title: "Where can I find the pricing table?",
          description: `<p>The pricing consumption table can be found within the <a href="/legal">legal</a> section or by clicking <a href="/legal/service-consumption-terms">here</a>.</p>`
        },
        {
          title: "What are Hippobyte's SLAs?",
          description: `<p>Detail on our uptime and data delivery SLAs can be found <a href="/legal/service-level-agreement">here</a>.</p>`
        },
        {
          title: "What if I have additional questions?",
          description: `<p>Head on over to our <a href="/platform/frequently-asked-questions">product FAQ page</a>, documentation, or get in touch with one of our <a href="/contact/schedule-demo">experts</a>.</p>`
        },
      ]}
    />
    <TalkWithExpert 
      pad="xlarge" 
      margin={{ top: "xlarge" }} 
      background="dark-4"
    />
    </PageLayout>
  )
}

export default Platform